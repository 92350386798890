<template>
  <div class="block-marquee bg-brand-primary text-brand-white py-2 text-center mb-12">
    <Vue3Marquee
      gradient
      clone
      :gradient-color="gradientColor"
    >
      <template v-for="(item, idx) in data.item" :key="idx">
        <span class="text-bold text-2xl mx-4">·</span><span class="block flex-shrink-0 text-xl">{{ item.label }}</span>
      </template>
    </Vue3Marquee>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { Vue3Marquee } from 'vue3-marquee';

defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const gradientColor = ref();

onMounted(() => {
  const rawRGB = getComputedStyle(document.documentElement).getPropertyValue('--brand-color-primary');
  gradientColor.value = rawRGB.split(' ');
});
</script>

<style>
.block-marquee {
  .marquee {
    /* Force GPU rendering */
    transform: rotate(0deg);
  }
}
</style>
